<template>
  <v-main >
     <v-container>

        <div v-show="!isLogined">
          <v-alert
                color="orange darken-1"
                dark
                icon="mdi-alert-octagram"
                
                elevation="1"
                dense
                prominent>

                사용자 정보 로드중입니다. 처음 로그인했으면 시간이 걸릴수 있습니다.
          </v-alert>
 
        </div>

        <v-skeleton-loader v-show="isLoading || !isLogined" type="card-avatar, article"></v-skeleton-loader>

        <v-row v-show="isLogined && isLoading==false">
        
          <!--메인:왼쪽 열  -->
          <v-col
            cols="12"
            lg="4">

            <v-card 
              elevation="12"
              rounded="lg"
              style="padding:25px;"
              min-height="330">

              <v-list-item style="margin-left:-20px">
                   <v-icon  color="primary"> mdi-account-box</v-icon>
                   <h2 class="ml-1">Profile</h2>
                 </v-list-item>

                 <br>
              
              <v-row justify="center" class="mb-2">
                  <v-avatar size="80" style="margin-top:-10px" color="white">
                  <img class="pa-1"
                    :src="profilePhotoURL"
                  >
                  </v-avatar>

              </v-row>

               ◾️ 이메일 : {{profileEmail}} <br>
                
               <div v-show="!isProfileNicknameEditMode"> ◾️ 활동명 : {{profileNickname}}</div>
                
                 <v-text-field
                  filled
                  hint="사이트 이용시 대표이름으로 표출이 됩니다."
                  persistent-hint
                  class="mt-5"
                  v-model="editProfileNickname"
                  v-show="isProfileNicknameEditMode"
                  outlined
                  label="활동명"
                  
                ></v-text-field>

               ◾️ 멤버십 : 0 lv 🌱<br> 
               <!-- ◾️ 가입일 : 2023-04-15 -->
          
                <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-chip @click="profileNicknameEdit" class="ml-3" outlined color="primary" v-show="!isProfileNicknameEditMode">
                    <v-avatar left>
                      <v-icon>mdi-account-edit-outline</v-icon>
                    </v-avatar>
                    EDIT
                  </v-chip>
                  <v-chip @click="profileNicknameSave" class="ml-3" outlined color="primary" v-show="isProfileNicknameEditMode">
                    <v-avatar left>
                      <v-icon>mdi-account-check-outline</v-icon>
                    </v-avatar>
                    SAVE
                  </v-chip>
                  <v-chip @click="profileNicknameCancel" class="ml-3" outlined color="red" v-show="isProfileNicknameEditMode">
                    <v-avatar left>
                      <v-icon>mdi-account-cancel-outline</v-icon>
                    </v-avatar>
                    CANCEL
                  </v-chip>

                </v-card-actions>
             
            </v-card>
          </v-col>

        <!--메인:왼쪽 열  -->

         <v-col
            cols="12"
            lg="8">

            <v-card 
              elevation="12"
              rounded="lg"
              style="padding:25px"
              min-height="330">

               <v-list-item style="margin-left:-20px">
                   <v-icon  color="primary">mdi-card-account-details</v-icon>
                   <h2 class="ml-1">Comments</h2>
                 </v-list-item>

                 <br>
             <v-textarea
              filled
              outlined
              v-show="isProfileCommentsEditMode"
              label="하고 싶은말"
              hint="수정할 내용을 입력후에 저장버튼으로 업데이트 해주세요"
              persistent-hint
              v-model="editProfileComments"></v-textarea>
 

                <!-- <p v-show="!isProfileCommentsEditMode">
                  {{profileComments}}
                </p> -->

                 <v-textarea
              filled
              outlined
              v-show="!isProfileCommentsEditMode"
              label="하고 싶은말"
              height="132"
              v-model="profileComments"> </v-textarea>

                 <!-- <div v-show="!isProfileCommentsEditMode" v-html="profileComments"></div> -->
 
                <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-chip @click="profileCommentEdit" class="ml-3" outlined color="primary" v-show="!isProfileCommentsEditMode">
                    <v-avatar left>
                      <v-icon>mdi-account-edit-outline</v-icon>
                    </v-avatar>
                    EDIT
                  </v-chip>
                  <v-chip @click="profileCommentSave" class="ml-3" outlined color="primary" v-show="isProfileCommentsEditMode">
                    <v-avatar left>
                      <v-icon>mdi-account-check-outline</v-icon>
                    </v-avatar>
                    SAVE
                  </v-chip>
                  <v-chip @click="profileCommentCancel" class="ml-3" outlined color="red" v-show="isProfileCommentsEditMode">
                    <v-avatar left>
                      <v-icon>mdi-account-cancel-outline</v-icon>
                    </v-avatar>
                    CANCEL
                  </v-chip>

                </v-card-actions>
             
            </v-card>
          </v-col>
         

        </v-row>

         <v-row v-show="isLogined && isLoading==false">
         
        <v-col
            v-show="eosAccountRequestStatus == '0'"
            cols="12"
            lg="12">

            <v-sheet 
            elevation="12"
              rounded="lg"
              style="padding:25px"
              min-height="400">

             <v-list-item style="margin-left:-20px">
                   <v-icon  color="primary">mdi-wallet</v-icon>
                   <h2 class="ml-1">EOS Account</h2>
                 </v-list-item>

                 <br>
<!-- 
            <p class="text-body">Get a EOS Account for Free. 😎<br>
            Just enter Account Name which you wanted to create.<br>
            Wait some minutes, you will get free EOS account</p> -->

            <p class="text-body">무료로 이오스계정을 생성하세요. 😎<br>
            아래의 계정명에 원하는 이름을 선택만 하면 자동생성이 됩니다.<br>
            계정명 뒤에 .qt 프리미엄 네임이 자동으로 들어갑니다.(최대 9자리만 가능)<br>
            요청전에 꼭 프라이빗키를 저장하세요!</p>

            <v-text-field 
            class="mt-10"
                    append-icon="mdi-account-heart"
                    v-model="newAccountName" 
                    label="EOS Account Name"
                    :hint="willCreateRealAccount"
                    persistent-hint
                    suffix=".qt"
                    :counter="9"
                    :rules="[rules.required, rules.max, rules.onlyEnlglishAndNumber]"
                    required
                    @keyup="afterAccountNameChanged"
                    outlined/>
             <p v-show="!isAlreadyUsed && isAccountValidShow" class="green--text"><v-icon color="green">mdi-check-circle</v-icon> 사용가능한 계정입니다.</p>
             <p v-show="isAlreadyUsed && isAccountValidShow" class="red--text"><v-icon color="red">mdi-close-circle</v-icon> 이미 생성된 계정입니다.</p>


            <v-chip class="mb-3 mt-3" @click="generateNewKeyPairs" outlined  color="primary">
                <v-avatar left>
                    <v-icon>mdi-key-chain-variant</v-icon>
                </v-avatar>
                Generate Keys</v-chip>
           
             <v-text-field class="mt-1"
                    append-icon="mdi-key-variant"
                    v-model="newAccountPublicKey" 
                    label="Pubic Key"
                    @paste="pasted"
                    required
                    outlined/>

            <v-text-field  v-show="isPrivateKeyShow" 
                    v-model="newAccountPrivateKey" 
                    append-icon="mdi-lock"
                    label="Private Key"
                    readonly
                    outlined/>

            <div v-show="isPrivateKeyShow">
             <!-- <p> <v-icon>mdi-shield-key</v-icon> Private Key :    -->
            
             <!-- <input size="70" style="border:none"
              v-on:focus="$event.target.select()" 
              ref="clone" 
              readonly 
              :value="newAccountPrivateKey"/>  -->
           
            <!-- <v-icon @click="toClipboard">mdi-file-document-check-outline</v-icon></p> -->
            <!-- <p class="red--text"><v-icon color="red">mdi-alert</v-icon> You should save and safely store your private keys.</p> -->
            <p class="red--text"><v-icon color="red">mdi-alert</v-icon> 꼭 프라이빗키를 저장해주세요.<br>
            You should safely store your private keys.</p>
            </div>
            <v-btn @click="creatNewAccountCheck" :loading="isCreateEOSAccountLoading" 
              :disabled="isCreateNewAccountDisable"  
              color="primary">무료 계정 생성</v-btn>
             
            </v-sheet>
        </v-col>
       
        <v-col
           v-show="eosAccountRequestStatus != '0'"
            cols="12"
            lg="12">

            <v-sheet 
            
            elevation="12"
              rounded="lg"
              style="padding:25px"
              min-height="400">

            <v-list-item style="margin-left:-20px">
                   <v-icon  color="primary">mdi-wallet</v-icon>
                   <h2 class="ml-1">EOS Account</h2>
                 </v-list-item>

                 <br>

            <!-- <p v-show="eosAccountRequestStatus == '1'" class="text-body">Now your request is submitted! 😉<br>
            Please wait some minutes, you will get a free EOS accont.</p>

             <p v-show="eosAccountRequestStatus == '2'" class="text-body">
             Your EOS Account <strong class="text-decoration-underline blue--text" style="cursor: pointer;" @click="openEOSAccount(profileEOSAccount)">{{newAccountName}}</strong> is successfully created! 🥰<br>
             Have a fun EOS web3 blockchain life with EOS Quest. 
             </p>

               <p v-show="eosAccountRequestStatus == '3'" class="text-body">
             Fail to create your EOS Account <strong>{{newAccountName}}</strong> 😥<br>
             Please contact to support team!
             </p> -->

             <p v-show="eosAccountRequestStatus == '1'" class="text-body">요청이 등록되었습니다! 😉<br>
            잠시 기다리면 무료 계정이 생성이 됩니다.</p>

             <p v-show="eosAccountRequestStatus == '2'" class="text-body">
             <strong class="text-decoration-underline blue--text" style="cursor: pointer;" @click="openEOSAccount(newAccountName)">{{newAccountName}}</strong> 계정이 성공적으로 생성이 되었습니다. 🥰<br>
             이오스퀘스트와 함께 즐거운 Web3를 경험하세요. 
             </p>

               <p v-show="eosAccountRequestStatus == '3'" class="text-body">
             <strong>{{newAccountName}}</strong> 계정 생성에 실패하였습니다. 😥<br>
             서포트팀에게 연락부탁드립니다.
             </p>

             <!-- <p v-show="eosAccountRequestStatus == '2'" class="text-decoration-underline blue--text" style="cursor: pointer;" @click="openEOSAccount(profileEOSAccount)">{{newAccountName}}</p> -->


             <v-progress-linear
              v-show="eosAccountRequestStatus == '1'"
              buffer-value="0"
              stream
              color="cyan"
            ></v-progress-linear>

            <v-text-field 
              disabled
              class="mt-10"
              readonly
              append-icon="mdi-account-heart"
              v-model="newAccountName" 
              label="EOS Account Name"
              outlined/>
           
             <v-text-field 
              disabled
              readonly
              append-icon="mdi-key-variant"
              v-model="newAccountPublicKey" 
              label="Pubic Key"
              @paste="pasted"
              required
              outlined/>

               <v-progress-linear
                v-show="eosAccountRequestStatus == '1'"
                buffer-value="0"
                reverse
                stream
                color="cyan"
              ></v-progress-linear>
        
            </v-sheet>
        </v-col>


      <v-col
            cols="12"
            lg="12">

            <v-sheet 
            
            elevation="12"
              rounded="lg"
              style="padding:25px"
              min-height="200">

             
                 <v-list-item style="margin-left:-20px">
                   <v-icon  color="primary">mdi-flash</v-icon>
                   <h2 class="ml-1">Daily Free Powerup!</h2>
                 </v-list-item>

                 <br>

            <p class="text-body">
             하루에 3번까지 무료 파워업이용이 가능합니다. 😉<br>
             텔레그램 파워업봇과 별개로 추가로 제공합니다.<br>
             금일 남은 파워업 :  {{powerupTodayUseCount}}/{{powerupMaxLimit}} </p>

             <p class="blue--text"><v-icon color="blue">mdi-information</v-icon> 회원의 등급에 따라서 일일 무료 파워업 횟수가 달라집니다.</p>

              <v-row>
                <v-col cols="12" lg="3"  >
                   <v-text-field
                  v-model="willPowerUPAccountName"
                  label="이오스 어카운트"
                  hint="파워업할 이오스 계정을 넣어주세요(다른계정도 가능)"
                  required
                  outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" lg="6">
                   <v-btn  class="mt-2" color="primary" :loading="isPowerUpLoading" :disabled="isPowerUPDisable" @click="powerUp">FREE POWER UP!</v-btn>

                </v-col>
              </v-row>

             
            
             <!-- <p v-show="eosAccountRequestStatus == '2'" class="text-decoration-underline blue--text" style="cursor: pointer;" @click="openEOSAccount(profileEOSAccount)">{{newAccountName}}</p> -->
 
        
            </v-sheet>
        </v-col>

        </v-row>

  <v-dialog
        v-model="dialog"
        max-width="320">

      <v-card>

        <v-card-title class="text-h6">

          <v-icon
          large
          color="green darken-1"
          style="margin-right:5px">
          mdi-checkbox-marked-circle-outline </v-icon>
 
         Free PowerUp!
        </v-card-title>

        <v-card-text>
        <br>
         🙌 파워업이 완료되었습니다!<br><br>
    
         향후 회원 등급에 따라서 횟수와 한번에 파워업하는 용량이 증가할 수 있습니다.🚀
         
         
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
 
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

   <v-dialog
        v-model="dialogPrivateKeyConfirm"
        max-width="320">

      <v-card>

        <v-card-title class="text-h6">

          <v-icon
          large
          color="red darken-1"
          style="margin-right:5px">
          mdi-checkbox-marked-circle-auto-outline </v-icon>
 
         중요!
        </v-card-title>

        <v-card-text>
        <br>
         프라이빗키를 저장하셨나요?<br><br>
    
         프라이빗키를 저장하지 않았다면 계정을 복구할 수 없습니다.<br><br>

        <p class="red--text"><v-icon color="red">mdi-alert</v-icon> 꼭 프라이빗키를 저장해주세요.</p>
         
         
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
 
          <v-btn
            color="green darken-1"
            outlined
            @click="creatNewAccount">
            저장했습니다
          </v-btn>
            <v-btn
            color="red darken-1"
            outlined
            @click="dialogPrivateKeyConfirm = false"
          >
            저장하지 않았습니다
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
     

      </v-container>
  </v-main>
</template>


<style scoped>

.infobox-1{

    border: 1px solid #e0e6ed;
    width: 50%;
    padding: 25px;
    border-radius: 6px;
    box-shadow: 0 2px 10px 1px rgb(31 45 61 / 10%);
    margin-right: auto;
    margin-left: auto;

}
 
</style>


<script>

const { generateKeyPair } = require('eosjs/dist/eosjs-key-conversions');  
const { KeyType } = require('eosjs/dist/eosjs-numeric');  

import { collection, getDocs,
         doc,updateDoc,deleteDoc,
         addDoc , serverTimestamp,
         getDoc,
         
        } from "firebase/firestore"; 

import { db } from '@/plugins/firebase';


export default {
  
  data() {
    return {
     dialog :false,
     dialogPrivateKeyConfirm:false,
     isLoading:true,
     isLogined:false,
     eosAccountRequestStatus:'0',
     profilePhotoURL:'',
     newAccountPublicKey:'',
     newAccountPrivateKey:'',
     newAccountName:'',
     willPowerUPAccountName:'',
     isPowerUpLoading:false,
     powerupMaxLimit:3,
     powerupTodayUseCount:0,
     profileEmail:'',
     profileComments:'한마디',
     editProfileComments:'',
     profileNickname:'닉네임을 설정해주세요',
     editProfileNickname:'',
     isProfileNicknameEditMode:false,
     isProfileCommentsEditMode:false,
     isAlreadyRequestEOSAccount:false,
     isCreateEOSAccountLoading:false,
     isPrivateKeyShow:false,
     isAlreadyUsed:false,
     isAccountValidShow:false,
     isAccoundNameValid:false,
     workerCheckingEOSAccountCreated:null,
     workerCheckingPowerupCompleted:null,
     rules: {
          required: value => !!value || 'Required.',
      
          onlyEnlglishAndNumber: v =>  /^[a-z1-5]{1,9}$/.test(v) || '영어와 1-5까지의 숫자 그리고 2자 이상 최대 9자만 가능합니다.',
        },
    
    }
  },
  computed:{

    isCreateNewAccountDisable(){

      
      if(this.newAccountPublicKey == '' || this.newAccountName == '') return true
      if(this.isAccoundNameValid == false)  return true
      if(this.isAlreadyRequestEOSAccount || this.isAlreadyUsed) return true

      return false
    },
    isPowerUPDisable(){

       if(this.isPowerUpLoading == true ) return true;

       return this.powerupTodayUseCount == this.powerupMaxLimit ? true:false;  

        
      },
     willCreateRealAccount()
     {
       if(this.newAccountName != '')
       {
           return  '생성될 계정 : ' + this.newAccountName + '.qt'
       }
       
     }

  },
  watch: {

  '$store.state.firebaseLoginUserInfo': function() {

    if(this.$store.state.firebaseLoginUserInfo == null)
    {
      this.isLogined = false
    }
    else{
      this.loadFirebaseLoginInfo()
    }

   }
  },
  methods: {

    openEOSAccount(val)
    {
      console.log(val)
      window.open('https://bloks.io/account/' + val, '_blank');
    },
    transactionLink(val){

      window.open('https://bloks.io/transaction/' + val, '_blank');
    },
   async afterAccountNameChanged()
    {

      // if(this.newAccountName.length != 12 )
      // {
      //    this.isAccoundNameValid = false
      //    this.isAccountValidShow = false
      //    return
      // }

      if( /^[a-z1-5]{1,9}$/.test(this.newAccountName) == false) 
      {
        this.isAccoundNameValid = false
         this.isAccountValidShow = false
         return
      }

      this.isAccoundNameValid = true
      this.isAccountValidShow = true
      this.isAlreadyUsed = await this.checkAccountOccupied()
    },
    async checkAccountOccupied()
    {
      try {

         var result =  await this.$eosjsRPC.get_account(this.newAccountName + '.qt')

        if(result != null)
        {
           return true
        }
        
      } catch (error) {
        return false
      }
      
    },

    pasted()
    {
       this.isPrivateKeyShow = false
    },
    toClipboard()
    {
      this.$refs.clone.focus();
      document.execCommand('copy');
    },
   generateNewKeyPairs()
   {
      const generatedKey = generateKeyPair(KeyType.k1, {
         secureEnv: true
       });

       this.newAccountPrivateKey = generatedKey.privateKey.toString()
       this.newAccountPublicKey = generatedKey.publicKey.toString()

       this.isPrivateKeyShow = true
   },
   async creatNewAccountCheck()
   {
      this.dialogPrivateKeyConfirm = true;
   },
   async creatNewAccount()
   {
      this.dialogPrivateKeyConfirm = false
      this.isCreateEOSAccountLoading = true;

      //현재 상태 업데이트
      //신규 요청 하기
      const docRef = doc(db,'users', this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid)
      await updateDoc(docRef, { 
        eosAccountRequestStatus:'1',
        accountName: this.newAccountName + '.qt',
        publicKey:this.newAccountPublicKey,
        });
       
      await addDoc(collection(db, "requestEOS"), 
      {
          uid: this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid,
          type: 'createAccount',
          accountName: this.newAccountName + '.qt',
          publicKey:this.newAccountPublicKey,
          transaction:'',
          processLog:'',
          regdate: serverTimestamp()
      });

      this.isCreateEOSAccountLoading = false;

      await this.loadFirebaseLoginInfo()
      this.startCheckingEOSAccountCreated()

   },
   async powerUp()
   {
     
      //TODO:
      //남은 갯수가 있는지 확인해서 powerup을 활성화 할지 정한다.
      //남은 횟수는 user에 powerup에 서 확인
      if(this.willPowerUPAccountName.length == 0) return;

      //계정명 유효성 판단
       if(this.willPowerUPAccountName.length < 2 )
      {
         alert('EOS계정 자릿수는 1자 이상이어야 합니다.')
         return
      }

      if( /^[a-z1-5.]{2,12}$/.test(this.willPowerUPAccountName) == false) 
      {
        alert('유효한 형식의 EOS계정명이 아닙니다.')
        return
      }

      this.isPowerUpLoading = true;
      
      //현재 상태 업데이트
      //신규 요청 하기
      const docRef = doc(db,'users', this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid)
      await updateDoc(docRef, { 
        powerupRequestStatus:'1', //1은요청중, 0은 대기상태(서버가 처리후 1->0으로)
        });
       
      await addDoc(collection(db, "requestEOS"), 
      {
          uid: this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid,
          type: 'powerup',
          accountName: this.willPowerUPAccountName,
          transaction:'',
          processLog:'',
          regdate: serverTimestamp()
      });
     
     
      //TODO:
      //요청한 결과에 대한 내용을user에서 불러오기
      //백엔드에서 처리완료휴 결과를 user에 업데이트해야한다.
      //백엔드에서 마지막 처리 내용과 남은 갯수를 업데이트 해준다.
      //lastpowerup { result:0,transaction:'', }
      //poweruphistory가 user에 있어야한다.

      await this.loadFirebasePowerupStatus()
      this.startCheckingPowerupCompleted()

   },
   async loadFirebaseLoginInfo()
   {

    this.eosAccountRequestStatus = '0'
    if( this.$store.state.firebaseLoginUserInfo != null)
    {
        this.isLoading = true
        this.profilePhotoURL = this.$store.state.firebaseLoginUserInfo.photoURL

        const docRef = doc(db, "users",  this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

          var nowUserInfo = docSnap.data()
          this.profileEmail = this.$store.state.firebaseLoginUserInfo.email
          this.profileNickname = nowUserInfo.nickName == '' ? '활동명을 설정해주세요.': nowUserInfo.nickName
          this.eosAccountRequestStatus = nowUserInfo.eosAccountRequestStatus
          this.profileComments = nowUserInfo.bio.replaceAll('<br>','\n')

          if(nowUserInfo.eosAccountRequestStatus == '0')
          {
            this.isAlreadyRequestEOSAccount = false
            this.generateNewKeyPairs()
          }
          else{
            this.isAlreadyRequestEOSAccount = true
            this.newAccountName = nowUserInfo.accountName
            this.newAccountPublicKey = nowUserInfo.publicKey
            this.willPowerUPAccountName = this.newAccountName
          }

          //파워업상태 조회하기
          await this.loadFirebasePowerupRemainCount();

          this.isLogined = true
          this.isLoading = false
            
        } else {

          //아직 Function에서 생성을 안하면
          this.isLogined = false
              
        }

    }

    //딱 한번만 실행해준다.
    if(this.workerCheckingEOSAccountCreated != null) this.clearCheckingEOSAccountCreated()

   },

   async loadFirebasePowerupStatus()
   {

    //클라이언트에서 파워업요청후 DB에서 정보 읽어와서 처리 완료
    //여부를 화면에 보여준다.
    //처리중이면 파워업버튼 Disable
    //처리완료면 파워업상태들 다시 읽어서 화면에 표출해준다.

    if( this.$store.state.firebaseLoginUserInfo != null)
    {
        const docRef = doc(db, "users",  this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {

          var nowUserInfo = docSnap.data()
          this.profileEmail = nowUserInfo.email

          this.eosAccountRequestStatus = nowUserInfo.eosAccountRequestStatus

          if(nowUserInfo.powerupRequestStatus == '0')
          {
            
            //실행중인 체킹워커 중단
             if(this.workerCheckingPowerupCompleted != null) this.clearCheckingPowerupcompleted()

             //처리완료 팝업 띄우줌
             //freepowerup테이블에서 정보를 읽어와서 상태 2/3 업데이트 해준다.
             await this.loadFirebasePowerupRemainCount();
             this.isPowerUpLoading = false;
             
             //파워업 완료를 센터에 띄워준다.!!
            this.dialog = true;

          }
            
        }  
    }

   },

   startCheckingEOSAccountCreated () {

		  this.workerCheckingEOSAccountCreated = setInterval(() => {
      
      this.loadFirebaseLoginInfo()
      
		}, 15000)
	},

   startCheckingPowerupCompleted () {

		  this.workerCheckingPowerupCompleted = setInterval(() => {
      
      this.loadFirebasePowerupStatus()
      
		}, 5000)
	},

  async loadFirebasePowerupRemainCount(){

    //DB에서 사용자의 남은 최대갯수와/현재갯수를 읽어온다.

    const docRef = doc(db, "freepowerup",  this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid);
    const docSnap = await getDoc(docRef);

    var willUpdateHistoryWhenSucess = []
    var maxLimit = 3; //기본 3회

    if (docSnap.exists()) {

      var tempDockData = docSnap.data()
      var tempHistory = tempDockData.history;

      if(tempHistory.length != 0)
      {
        maxLimit = this.getGradValue(tempDockData.grade);
        var nowDateTime = Date.now();
      
        tempHistory.forEach( (value) => {

            var expireUnixTimeStamp = value + (24 * 60 * 60 * 1000)
            
            //현재시간 기준으로 24시간이 지났는지 판단
            //안지났으면 willUpdateHistoryWhenSucess 에 추가
            if (expireUnixTimeStamp > nowDateTime) willUpdateHistoryWhenSucess.push(value);
        })
         
      }
            
     }

    this.powerupTodayUseCount = willUpdateHistoryWhenSucess.length;
    this.powerupMaxLimit = maxLimit

  },
  
  getGradValue(grade) {
  switch (grade) {
    case 0:
      return 3;
    case 1:
      return 4;
    case 2:
      return 5;
    case 3:
      return 10;
    case 4:
      return 15;
    case 5:
      return 20;
    default:
      return 2;
  }
 },

  
  clearCheckingEOSAccountCreated()
  {
    if(this.workerCheckingEOSAccountCreated != null) 
    {
      clearInterval(this.workerCheckingEOSAccountCreated);
      this.workerCheckingEOSAccountCreated = null;
    }
  },

   clearCheckingPowerupcompleted()
  {
    if(this.workerCheckingPowerupCompleted != null) 
    {
      clearInterval(this.workerCheckingPowerupCompleted);
      this.workerCheckingPowerupCompleted = null;
    }
  },
    
   profileCommentEdit()
    {
      
      this.editProfileComments = this.profileComments.replaceAll('<br>','\n')
      this.isProfileCommentsEditMode = true

    },

   async profileCommentSave()
    {
      //파이어베이스에 저장하기
      //성공하면 화면에 저장된 내용 업데이트

      if(this.editProfileComments == this.profileComments)
      {
        this.isProfileCommentsEditMode = false;
        return;
      }
  
      const docRef = doc(db,'users', this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid)
      await updateDoc(docRef, { 
          bio:this.editProfileComments.replaceAll('\n','<br>'),  
        }).then((doc) =>{
          //성공
          this.profileComments = this.editProfileComments
        }).catch((error) => {
          //실패
          alert('업데이트에 실패하였습니다.')
        });
      
      //실패하면 오류 메시지
      this.isProfileCommentsEditMode = false
    },

     profileCommentCancel()
    {
      this.editProfileComments = ''
      this.isProfileCommentsEditMode = false
    },


     profileNicknameEdit()
    {
      this.editProfileNickname = this.profileNickname
      this.isProfileNicknameEditMode = true

    },

    async profileNicknameSave()
    {
      //파이어베이스에 저장하기
      //성공하면 화면에 저장된 내용 업데이트
       //현재 상태 업데이트
      //신규 요청 하기

      if(this.editProfileNickname == this.profileNickname) 
      {
        this.isProfileNicknameEditMode = false;
        return;
      }

       if(this.editProfileNickname.length > 20) 
       {
         alert('20자 이내로 설정해주세요!')
         return;
       }

      const docRef = doc(db,'users', this.$store.state.firebaseLoginUserInfo.auth.lastNotifiedUid)
      await updateDoc(docRef, { 
          nickName:this.editProfileNickname,  
        }).then((doc) =>{
          //성공
          this.profileNickname = this.editProfileNickname
        }).catch((error) => {
          //실패
          alert('업데이트에 실패하였습니다.')
        });

      this.isProfileNicknameEditMode = false
    },

     profileNicknameCancel()
    {
      this.editProfileNickname = ''
      this.isProfileNicknameEditMode = false
    },

  },
  
  mounted() {

  //1.클라우드에서 사용자의 정보를 가져온다.
  //2.user.eosaccountstatus : 0(요청안함),1(요청후 대기중),2(생성완료),3(생성실패)
  //3.user.eosaccountcreateddate
  //console.log(this.$route.params);

  this.loadFirebaseLoginInfo()
  
},

}
</script>

